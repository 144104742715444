@media screen and (max-width: 600px) {
    .sound_row{
        margin-left: 3%;
        height: 40px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .sound_title{
        font-size: 25px;
    }

    .input_box{
        font-size:16px;
        width: 27vw;
        height: 8vw;
    }
    #labeling{
        margin-right: 10px;
    }
}