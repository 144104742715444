.step {
    box-shadow: -3px -3px 3px rgba(134, 134, 134, 0.7),
              -3px -3px 3px rgba(255, 255, 255, .5),
              4px 4px 3px #383737,
              4px 4px 3px rgba(0, 0, 0, .15);
    cursor: pointer;
}

.step_clicked {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
              -2px -2px 4px rgba(255, 255, 255, 0.811),
              2px 2px 2px rgba(236, 235, 235, 0.692),
              2px 2px 4px rgba(255, 255, 255, 0.1);
    animation: rotateAnimation 3s linear infinite;
}

.step_hovered:hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
              -2px -2px 4px rgba(255, 255, 255, 0.811),
              2px 2px 2px rgba(236, 235, 235, 0.692),
              2px 2px 4px rgba(255, 255, 255, 0.1);
    animation: pulse 1s ease-in-out infinite;
}

.step_played{
    background-color: #FF69B4;
}

.hidden-circle {
    width: 1.5vw; 
    height: 1.5vw;
    position: absolute; 
    top: 0; 
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; 
    background-color: #FF69B4; 
    border-radius: 50%; 
}


@keyframes rotateAnimation {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 600px) {
    .step{
        width:40px;
    }
    .hidden-circle {
        width: 3vw; 
        height: 3vw;
    }
    .step_clicked {
        box-shadow: -2px -2px 6px rgba(223, 64, 231, 0.81),
                  -2px -2px 4px rgba(218, 53, 207, 0.811),
                  2px 2px 2px rgba(176, 36, 200, 0.692),
                  2px 2px 4px rgba(224, 29, 204, 0.62);
        animation: rotateAnimation 3s linear infinite;
    }
}